import * as React from 'react'
import { Filter, List, TextField, TextInput, SelectInput, usePermissions, ReferenceInput, AutocompleteInput } from 'react-admin'
import { CustomActionsButtons } from '../CustomFields/CustomActionButtonsField'
//import CustomDataGrid from "./OldCustomDataGrid";
import CustomDataGrid from '../CustomElements/CustomDataGrid'
import CustomActionBar from '../CustomElements/CustomActionBar'

import PermissionHandler from '../../config/PermissionHandler'

import EmptyList from '../EmptyList'

import { CustomPagination } from '../CustomElements/CustomPagination'

const isAvailable = (record) => {
    if ([2, 3].includes(record.accepted)) return true
    else return false
}

const types = [
    { id: 1, name: 'zaakceptowany' },
    { id: 3, name: 'odrzucony' },
    { id: 4, name: 'adres płatnika - do wykluczenia' },
]

export default (props) => {
    const { isLoading, permissions } = usePermissions()
    const [actualNetworkPayer, setActualNetworkPayer] = React.useState('')
    const [payer, setPayer] = React.useState(null)
    React.useEffect(() => {
        const url = decodeURI(window.location.hash)
        const searchParams = new URLSearchParams(url)
        const filterObj = JSON.parse(searchParams.get('filter'))
        if (filterObj && filterObj.payer) {
            setActualNetworkPayer(filterObj.payer.payerNetwork)
        }
        const payerId = window.location.hash?.split("?payer=")?.[1]
        if (payerId) {
            setPayer(payerId)
        }
    },[payer])

    return (
        <List
            {...props}
            pagination={<CustomPagination />}
            filter={payer ? { payer: `${payer}` } : null}
            empty={<EmptyList title="punktów odbioru" add={PermissionHandler(permissions, 'PickupPoints', 'Create')} />}
            actions={<CustomActionBar isAddVisible={PermissionHandler(permissions, 'PickupPoints', 'Create')} />}
            filters={[
                PermissionHandler(permissions, 'PickupPoints', 'Update') ? (
                    <SelectInput label="Status" source="accepted" choices={types} alwaysOn variant="outlined" resettable />
                ) : (
                    <></>
                ),
                <TextInput variant="outlined" resettable label="Nazwa" source="internalName" alwaysOn />,
                PermissionHandler(permissions, 'PickupPoints', 'Update') ? (
                    <TextInput variant="outlined" resettable label="Numer PKODB" source="pkodbNumber" alwaysOn />
                ) : (
                    <></>
                ),
                PermissionHandler(permissions, 'PickupPoints', 'Update') ? (
                    <TextInput variant="outlined" resettable label="Platnik" source="payer.name" alwaysOn />
                ) : (
                    <></>
                ),

                PermissionHandler(permissions, 'PickupPoints', 'Update') ? (
                    <ReferenceInput
                        source="payer.payerNetwork"
                        reference="payer_networks"
                        link={false}
                        className="autocompleteinput"
                        alwaysOn
                        allowEmpty
                    >
                        <AutocompleteInput
                            label="Płatnik sieciowy"
                            optionText="name"
                            variant="outlined"
                            resettable
                            allowEmpty
                            filterToQuery={(searchText) => ({
                                name: searchText,
                            })}
                            onChange={(change) => {
                                setActualNetworkPayer(change)
                            }}
                        />
                    </ReferenceInput>
                ) : (
                    <></>
                ),
                // PermissionHandler(permissions, 'PickupPoints', 'Update') ? (
                //     <ReferenceInput
                //         source="payer"
                //         reference="payers"
                //         className="autocompleteinput"
                //         link={false}
                //         alwaysOn
                //         allowEmpty
                //         filter={{ payerNetwork: actualNetworkPayer }}
                //     >
                //         <AutocompleteInput
                //             label="Płatnik"
                //             optionText="name"
                //             variant="outlined"
                //             filterToQuery={(searchText) => ({
                //                 name: searchText,
                //             })}
                //             resettable
                //         />
                //     </ReferenceInput>
                // ) : (
                //     <></>
                // ),
            ]}
            bulkActionButtons={false}
            title={`Punkty odbioru`}
        >
            <CustomDataGrid>
                <TextField source="name" label="Nazwa" />
                <TextField source="city" label="Miejscowość" />
                <TextField source="postCode" label="Kod pocztowy" sortable={false} />
                <TextField source="street" label="Ulica" />
                <TextField source="voivodeshipName" label="Województwo" />
                <TextField source="payerName" label="Płatnik" />
                <TextField source="pkodbNumber" label="Numer PKODB" />
                <TextField source="payerNetworkName" label="Płatnik sieciowy" />

                {PermissionHandler(permissions, 'PickupPoints', 'Read') && (
                    <CustomActionsButtons
                        label="Akcje"
                        isEditAvailable={PermissionHandler(permissions, 'PickupPoints', 'Update')}
                        isDeleteAvailable={PermissionHandler(permissions, 'PickupPoints', 'Delete')}
                        isDescriptionAvailable={PermissionHandler(permissions, 'PickupPoints', 'Read')}
                        isGenerateTemporaryUsersAvailable={PermissionHandler(permissions, 'PickupPoints', 'GenerateTemporaryUsers')}
                    />
                )}
            </CustomDataGrid>
        </List>
    )
}
