import * as React from 'react'
import {
    Divider,
    Typography,
    Card,
    Box,
    TextField as MuiTextField,
    Button as MuiButton,
    TableContainer,
    TableHead,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Skeleton,
} from '@mui/material'
import {
    Show,
    SimpleShowLayout,
    TextField,
    ReferenceField,
    BooleanField,
    Link,
    useRecordContext,
    useShowController,
    EditButton,
    Button,
    useDataProvider,
    useRedirect,
    useRefresh,
} from 'react-admin'
import { CustomTitle } from '../CustomElements/CustomTitle'
import { TextFieldShowView } from '../CustomFields/CustomFields'
import RefundScale from './partials/RefundScale'
import { useConfirm } from 'material-ui-confirm'
import { useSnackbar } from 'notistack'
import ezwmProvider from '../../config/ezwmProvider'

const fontStyle = {
    padding: '2%',
    color: '#d4731c',
    textTransform: 'normal',
    textAlign: 'center',
    fontSize: '1rem',
}

const menuElementContainer = {
    display: 'flex',
    width: '100%',
    minWidth: 250,
    minHeight: 100,
    maxHeight: 100,
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2em',
    textDecoration: 'none',
}

export default () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const redirect = useRedirect()
    const refresh = useRefresh()

    const RefundScaleWrapper = () => {
        const { record } = useShowController()
        const [refundStatuses, setRefundStatuses] = React.useState(null)

        if (record?.refundDocument && !refundStatuses) {
            ezwmProvider
                .getRefundDocStatuses(record?.refundDocument?.refundNumber, record?.refundDocument?.pesel)
                .then((data) => setRefundStatuses(data))
        }

        return <RefundScale refundMonths={refundStatuses} />
    }

    const RefundInfoView = () => {
        const { record } = useShowController()
        const [refundNumber, setRefundNumber] = React.useState(null)
        const [identity, setIdentity] = React.useState(null)
        const [loading, setLoading] = React.useState(false)

        const getRefundDocument = () => {
            if (refundNumber && identity) {
                setLoading(true)
                ezwmProvider
                    .getRefundDoc(refundNumber, identity, record._id)
                    .then((data) => {
                        enqueueSnackbar('Zlecenie zostało pomyślnie dodane', {
                            variant: 'success',
                            autoHideDuration: 5000,
                        })
                        refresh()
                    })
                    .catch((err) =>
                        enqueueSnackbar('Wystąpił błąd', {
                            variant: 'error',
                            autoHideDuration: 5000,
                        })
                    )
                    .finally(() => setLoading(false))
            }
        }

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ marginBottom: 4 }}>
                    <Typography variant="h5" gutterBottom>
                        Zlecenie NFZ
                    </Typography>
                    <Card
                        sx={{
                            marginTop: '1em',
                            padding: '8px 16px',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <MuiTextField
                            variant="outlined"
                            label="Numer zlecenia / PIN"
                            value={refundNumber}
                            onChange={(e) => setRefundNumber(e.target.value)}
                        />
                        <MuiTextField variant="outlined" label="PESEL" value={identity} onChange={(e) => setIdentity(e.target.value)} />
                        <MuiButton disabled={!refundNumber || !identity || loading} variant="contained" color="success" onClick={getRefundDocument}>
                            Pobierz
                        </MuiButton>
                    </Card>
                </Box>
                {record?.refundDocument && (
                    <>
                        <Box>
                            <Typography variant="h5" gutterBottom>
                                Dane zlecenia
                            </Typography>
                            <Card
                                sx={{
                                    marginTop: '1em',
                                    padding: '8px 16px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <TextFieldShowView label="Imię" text={record?.refundDocument?.name} />
                                <TextFieldShowView label="Nazwisko" text={record?.refundDocument?.surname} />
                                <TextFieldShowView label="Numer Pesel" text={record?.refundDocument?.pesel} />
                                <TextFieldShowView label="Numer zlecenia" text={record?.refundDocument?.refundNumber} />
                                <TextFieldShowView
                                    label="Okres realizacji"
                                    text={`${record?.refundDocument?.validFrom}:${record?.refundDocument?.validTo}`}
                                />
                                <TextFieldShowView label="Miesięczna ilość" text={`${record?.refundDocument?.spanLimit} szt`} />
                                <TextFieldShowView label="Dostępna ilość" text={`${record?.refundDocument?.availableLimit} szt`} />
                            </Card>
                        </Box>
                        <Box>
                            <Card
                                sx={{
                                    marginTop: '1em',
                                    padding: '8px 16px',
                                }}
                            >
                                <RefundScaleWrapper />
                            </Card>
                        </Box>
                    </>
                )}
            </Box>
        )
    }

    const RefundProductsView = () => {
        const { record } = useShowController()

        return (
            <Box>
                <Typography variant="h5" gutterBottom>
                    Przypisane produkty
                </Typography>
                <Card
                    sx={{
                        marginTop: '1em',
                        padding: '8px 16px',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Zdjęcie</TableCell>
                                    <TableCell>SKU</TableCell>
                                    <TableCell>Nazwa</TableCell>
                                </TableRow>
                            </TableHead>
                            {record ? (
                                <TableBody>
                                    {record?._patientProducts?.length > 0 ? (
                                        record?._patientProducts.map((prod) => (
                                            <TableRow>
                                                <TableCell>{prod.imgUrl ?? '-'}</TableCell>
                                                <TableCell>{prod.sku}</TableCell>
                                                <TableCell>{prod.name}</TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <Typography variant="subtitle2" display="table-cell" width="100%" textAlign="center" padding="10px">
                                            Brak wyników
                                        </Typography>
                                    )}
                                </TableBody>
                            ) : (
                                <>
                                    <Skeleton sx={{ display: 'table-caption' }} />
                                    <Skeleton sx={{ display: 'table-caption' }} />
                                    <Skeleton sx={{ display: 'table-caption' }} />
                                </>
                            )}
                        </Table>
                    </TableContainer>
                </Card>
            </Box>
        )
    }

    const EditBtn = () => {
        const { record } = useShowController()
        const dataProvider = useDataProvider()
        const confirm = useConfirm()

        const softDelete = () => {
            confirm({
                title: 'Uwaga!',
                description: 'Czy na pewno chcesz zarchiwizować dane tego pacjenta?',
                confirmationText: 'Tak',
                confirmationButtonProps: {
                    variant: 'outlined',
                    color: 'success',
                },
                cancellationText: 'Anuluj',
                cancellationButtonProps: {
                    variant: 'contained',
                    color: 'primary',
                },
            })
                .then(() =>
                    dataProvider
                        .delete('patients', { id: record.id })
                        .then((r) => {
                            enqueueSnackbar('Pacjent został przeniesiony do archiwum', {
                                variant: 'success',
                                autoHideDuration: 5000,
                            })
                        })
                        .catch((e) =>
                            enqueueSnackbar('Wystąpił błąd', {
                                variant: 'error',
                                autoHideDuration: 5000,
                            })
                        )
                        .finally(() => {
                            redirect('list', 'patients')
                        })
                )
                .catch(() => {
                    return
                })
        }

        return (
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <EditButton variant="outlined" color="success" record={record} size="large" />
                <Button color="warning" variant="outlined" label="Przenieś do archiwum" size="large" onClick={() => softDelete()} />
            </Box>
        )
    }

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                justifyContent: 'space-between',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    padding: 4,
                    justifyContent: 'space-between',
                }}
            >
                <Box sx={{ minWidth: 500 }}>
                    <Typography variant="h5" gutterBottom>
                        Dane podopiecznego
                    </Typography>
                    <Show title={<CustomTitle resourceName="Karta pacjenta" fields={['name', 'surname']} />} actions={null}>
                        <SimpleShowLayout>
                            <TextField label="Imię" source="name" />
                            <TextField label="Drugie imię" source="secondName" emptyText="-" />
                            <TextField label="Nazwisko" source="surname" />
                            <TextField label="Numer Pesel" source="pesel" />
                            <TextField label="Inny dokument" source="otherIdentity" />
                            <Divider sx={{ margin: '10px 0px 20px 0' }} />
                            <Typography variant="subtitle2" gutterBottom>
                                Dane dodatkowe jeśli adres inny niż placówki
                            </Typography>
                            <TextField label="Ulica" source="street" />
                            <TextField label="Numer" source="number" />
                            <TextField label="Kod pocztowy" source="postCode" />
                            <TextField label="Miasto" source="city" />
                            <Divider sx={{ margin: '10px 0px 20px 0' }} />
                            <TextField label="Oddział" source="_department.name" />
                            <TextField label="Numer pokoju" source="room" />
                            <BooleanField label="Status" source="status" valueLabelTrue="Aktywny" valueLabelFalse="Nieaktywny" />
                        </SimpleShowLayout>
                    </Show>
                </Box>
                <Box
                    sx={{
                        minWidth: 500,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <RefundInfoView />
                    <EditBtn />
                </Box>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            marginTop: '10px',
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                        }}
                    >
                        <Link to="/#" style={menuElementContainer} className="dash-card" sx={{ marginBottom: '10px' }}>
                            <span style={fontStyle}>Historia realizacji wniosków</span>
                        </Link>
                        <Link to="/#" style={menuElementContainer} className="dash-card">
                            <span style={fontStyle}>Historia zamówień</span>
                        </Link>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    padding: 4,
                }}
            >
                <RefundProductsView />
            </Box>
        </Card>
    )
}
