import * as React from 'react'
import {
    Typography,
    Box,
    TextField,
    TableContainer,
    TableHead,
    Table,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material'
import dayjs from 'dayjs'

const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrAfter)

export default ({ refundMonths }) => {
    if(!refundMonths || refundMonths?.length == 0){
        return "Brak";
    }

    const [actualRefundMonths, setActualRefundMonths] =
        React.useState(refundMonths)
    const today = dayjs().date(1).hour(0).minute(0).second(0).millisecond(0)
    const totalMonths = 12

    React.useEffect(() => {
        const filtered = refundMonths?.filter((el) =>
            dayjs(Object.keys(el)?.[0], 'YYYY-MM').isSameOrAfter(today)
        )
        const countFiltered = filtered.length
        if (countFiltered < totalMonths) {
            for (let i = countFiltered; i < totalMonths; i++) {
                let date = filtered[i - 1] ? Object.keys(filtered[i - 1])?.[0] : undefined;
                let monthStatus = dayjs(date).add(
                    1,
                    'month'
                )
                let result = {}
                result[monthStatus.format('YYYY-MM')] = 'unavailable'
                filtered.push(result)
            }
        }
        setActualRefundMonths(filtered)
    }, [refundMonths])

    const months = [
        '',
        'I',
        'II',
        'III',
        'IV',
        'V',
        'VI',
        'VII',
        'VIII',
        'IX',
        'X',
        'XI',
        'XII',
    ]

    const convertMonth = (date) => {
        return months[Number(date?.split('-')?.[1])]
    }

    const getHeaders = (data) => {
        const yearsCount = actualRefundMonths
            ?.map((month) => Object.keys(month)?.[0]?.split('-')?.[0])
            ?.reduce((cnt, cur) => ((cnt[cur] = cnt[cur] + 1 || 1), cnt), {})

        return Object.entries(yearsCount)?.map(([year, count], i) => (
            <TableCell
                sx={{
                    backgroundColor: `rgb(${204 - i * 10},${229 - i * 15},255)`,
                    border: '2px solid #ffffff',
                }}
                colSpan={count}
            >
                {year}
            </TableCell>
        ))
    }

    const getBody = (data) => {
        return actualRefundMonths.map((month) => {
            const [monthStatus] = Object.entries(month)
            return (
                <TableCell
                    sx={{
                        width: 20,
                        height: 20,
                        border: '2px solid #ffffff',
                        backgroundColor: getColor(monthStatus[1]),
                    }}
                >
                    {convertMonth(monthStatus[0])}
                </TableCell>
            )
        })
    }

    const getColor = (status) => {
        switch (status) {
            case 'active':
                return 'rgb(212, 237, 218)'
            case 'inactive':
                return 'rgb(248, 215, 218)'
            default:
                return 'rgb(226, 227, 229)'
        }
    }

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>{getHeaders()}</TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>{getBody()}</TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}
