import React, { useEffect, useState } from 'react'
import { useMediaQuery } from '@mui/material'
import { MenuItemLink, useDataProvider, usePermissions, useGetOne } from 'react-admin'

import { useSnackbar } from 'notistack'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCheckSquare,
    faCommentDollar,
    faFileInvoiceDollar,
    faHandHoldingUsd,
    faHandshake,
    faHistory,
    faHome,
    faIdCardAlt,
    faMapMarkedAlt,
    faMoneyBillWave,
    faNetworkWired,
    faReceipt,
    faTable,
    faUserGraduate,
    faUsers,
    faDownload,
    faBook,
    faMailBulk,
    faPause,
    faHouseUser,
    faBuilding,
    faUsersRectangle,
    faHospitalUser,
    faMountainSun,
    faTableCells,
    faCartShopping,
    faUserGear
} from '@fortawesome/free-solid-svg-icons'

import PermissionHandler from '../../config/PermissionHandler'
import CustomMultiItemMenu from '../CustomElements/CustomMultiItemMenu'

const _sx = {
    '&.RaMenuItemLink-active': {
        borderLeft: '3px solid #240a51',
    },
}

const Menu = () => {
    const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'))
    const { isLoading: loading, permissions } = usePermissions()
    const [podImage, setPodImage] = useState('/podS.png')

    const {
        data,
        loading: dataLoading,
        error,
    } = useGetOne('pk_users', {
        id: `pk_users/me`,
    })

    return !loading ? (
        <div className="left-menu" style={{paddingBottom: '30px'}}>
            <MenuItemLink to="/#" primaryText="Strona główna" leftIcon={<FontAwesomeIcon icon={faHome} />} sx={_sx} />
            <hr />
            {PermissionHandler(permissions, 'DpsOrder', 'Read') && (
                <MenuItemLink
                    to="/departments/new_order"
                    primaryText="Nowe zamówienie"
                    leftIcon={<FontAwesomeIcon icon={faCartShopping} />}
                    sx={_sx}
                />
            )}
            {PermissionHandler(permissions, 'Invoices', 'Read') && (
                <MenuItemLink to="/invoices" primaryText="Faktury" leftIcon={<FontAwesomeIcon icon={faFileInvoiceDollar} />} sx={_sx} />
            )}
            {PermissionHandler(permissions, 'Orders', 'Read') && (
                <MenuItemLink to="/orders" primaryText="Historia zamówień" leftIcon={<FontAwesomeIcon icon={faHistory} />} sx={_sx} />
            )}
            {PermissionHandler(permissions, 'IndividualOrders', 'Read') &&
                localStorage.getItem('indOrds') == 'true' && (
                <CustomMultiItemMenu
                    parentText="Zamówienia indywidualne"
                    childrens = {[
                        {text: 'Historia zamówień', link: '/individual_orders',icon: <FontAwesomeIcon icon={faHouseUser} />},
                        {text: 'Złóż zamówienie', link: '/individual_orders/create', icon: <FontAwesomeIcon icon={faCartShopping} /> }
                    ]}
                />
            )}
            {PermissionHandler(permissions, 'Departments', 'Read') && (
                <MenuItemLink
                    to="/departments"
                    primaryText="Oddziały"
                    leftIcon={<FontAwesomeIcon icon={faBuilding} />}
                />
            )}
            {PermissionHandler(permissions, 'Banners', 'Read') && (
                <MenuItemLink
                    to="/banners"
                    primaryText="Banery"
                    leftIcon={<FontAwesomeIcon icon={faTableCells} />}
                    sx={_sx}
                />
            )}
            {PermissionHandler(permissions, 'MediaObjects', 'Read') && (
                <MenuItemLink
                    to="/media_objects"
                    primaryText="Zdjęcia"
                    leftIcon={<FontAwesomeIcon icon={faMountainSun} />}
                    sx={_sx}
                />
            )}
            {PermissionHandler(permissions, 'PriceLists', 'Read') && (
                <MenuItemLink to="/price_lists" primaryText="Cenniki" leftIcon={<FontAwesomeIcon icon={faMoneyBillWave} />} sx={_sx} />
            )}

            {PermissionHandler(permissions, 'Patients', 'Read') && (
                <MenuItemLink to="/patients" primaryText="Podopieczni" leftIcon={<FontAwesomeIcon icon={faHospitalUser} />} sx={_sx} />
            )}

            {PermissionHandler(permissions, 'Partners', 'Read') && (
                <MenuItemLink to="/partners" primaryText="Partnerzy" leftIcon={<FontAwesomeIcon icon={faHandshake} />} sx={_sx} />
            )}

            {PermissionHandler(permissions, 'PartnerUsers', 'Read') && (
                <MenuItemLink to="/partner_users" primaryText="Partnerzy - użytkownicy" leftIcon={<FontAwesomeIcon icon={faUsers} />} sx={_sx} />
            )}
            {PermissionHandler(permissions, 'PayerNetworks', 'Read') && (
                <MenuItemLink to="/payer_networks" primaryText="Płatnicy sieciowi" leftIcon={<FontAwesomeIcon icon={faNetworkWired} />} sx={_sx} />
            )}
            {PermissionHandler(permissions, 'PayerNetworkUsers', 'Read') && (
                <MenuItemLink
                    to="/payer_network_users"
                    primaryText="Płatnicy sieciowi - użytkownicy"
                    leftIcon={<FontAwesomeIcon icon={faUsers} />}
                    sx={_sx}
                />
            )}

            {PermissionHandler(permissions, 'Payers', 'Read') && (
                <MenuItemLink to="/payers" primaryText="Płatnicy" leftIcon={<FontAwesomeIcon icon={faCommentDollar} />} sx={_sx} />
            )}
            {PermissionHandler(permissions, 'PayerUsers', 'Read') && (
                <MenuItemLink to="/payer_users" primaryText="Płatnicy użytkownicy" leftIcon={<FontAwesomeIcon icon={faUsers} />} sx={_sx} />
            )}

            {PermissionHandler(permissions, 'PickupPoints', 'Read') && (
                <MenuItemLink to="/pickup_points" primaryText="Punkty odbioru" leftIcon={<FontAwesomeIcon icon={faMapMarkedAlt} />} sx={_sx} />
            )}

            {PermissionHandler(permissions, 'Workers', 'Read') && (
                <MenuItemLink to="/workers" primaryText="Pracownicy" leftIcon={<FontAwesomeIcon icon={faIdCardAlt} />} sx={_sx} />
            )}
            {PermissionHandler(permissions, 'DepartmentUsers', 'Read') && (
                <MenuItemLink
                    to="/department_users"
                    primaryText="Pracownicy oddziałow"
                    leftIcon={<FontAwesomeIcon icon={faUsersRectangle} />}
                    sx={_sx}
                />
            )}
            {PermissionHandler(permissions, 'Samples', 'Read') && (
                <MenuItemLink
                    to="/samples"
                    primaryText="Próbki"
                    leftIcon={<img alt="próbki" src={podImage} height="25" />}
                    sx={_sx}
                    onMouseOver={(e) => {
                        setPodImage('/podS_white.png')
                    }}
                    onMouseOut={(e) => {
                        setPodImage('/podS.png')
                    }}
                />
            )}
            {PermissionHandler(permissions, 'RefundMenu', 'Read') && (
                <MenuItemLink to="/refundation-menu" primaryText="Dofinansowanie NFZ" leftIcon={<FontAwesomeIcon icon={faReceipt} />} sx={_sx} />
            )}

            {PermissionHandler(permissions, 'Trainings', 'Read') && (
                <MenuItemLink to="/trainings/*" primaryText="Szkolenia" leftIcon={<FontAwesomeIcon icon={faBook} />} sx={_sx} />
            )}

            {PermissionHandler(permissions, 'Tutorials', 'Read') && (
                <MenuItemLink to="/tutorials" primaryText="Szkolenia" leftIcon={<FontAwesomeIcon icon={faUserGraduate} />} sx={_sx} />
            )}

            {PermissionHandler(permissions, 'Raports', 'Read') && (
                <MenuItemLink to="/raports" primaryText="Pobierz raporty" leftIcon={<FontAwesomeIcon icon={faDownload} />} sx={_sx} />
            )}

            <hr />
            {PermissionHandler(permissions, 'PkUsers', 'Read') && (
                <MenuItemLink to="/pk_users" primaryText="Użytkownicy panelu" leftIcon={<FontAwesomeIcon icon={faUsers} />} sx={_sx} />
            )}

            {PermissionHandler(permissions, 'Regulations', 'Read') && (
                <MenuItemLink to="/regulations" primaryText="Regulaminy" leftIcon={<FontAwesomeIcon icon={faCheckSquare} />} sx={_sx} />
            )}

            {PermissionHandler(permissions, 'Consents', 'Read') && (
                <MenuItemLink to="/consents" primaryText="Zgody" leftIcon={<FontAwesomeIcon icon={faCheckSquare} />} sx={_sx} />
            )}

            {PermissionHandler(permissions, 'PartnerEmails', 'Read') && (
                <MenuItemLink to="/partner_emails" primaryText="Szablony e-mail" leftIcon={<FontAwesomeIcon icon={faMailBulk} />} sx={_sx} />
            )}

            {PermissionHandler(permissions, 'TechnicalPause', 'Read') && (
                <MenuItemLink to="/technical-pause" primaryText="Przerwa techniczna" leftIcon={<FontAwesomeIcon icon={faPause} />} sx={_sx} />
            )}

            <MenuItemLink to="/mydata" primaryText="Moje dane" leftIcon={<FontAwesomeIcon icon={faTable} />} sx={_sx} />
            {PermissionHandler(permissions, 'Oracle', 'Read') && (
                <MenuItemLink to="/admin-control-panel" primaryText="Oracle" leftIcon={<FontAwesomeIcon icon={faUserGear} />} sx={_sx} />
            )}
        </div>
    ) : null
}

export default Menu
