import * as React from 'react'
import { useGetOne, Show, SimpleShowLayout, TextField, usePermissions, Link, ReferenceField, FunctionField } from 'react-admin'
import { Box, Card, Typography, Divider, Button } from '@mui/material'
import { useParams } from 'react-router-dom'
import PermissionHandler from '../../../config/PermissionHandler'
import { CustomTitle } from '../../CustomElements/CustomTitle'
import { TextFieldShowView } from '../../CustomFields/CustomFields'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStoreAlt, faBuilding } from '@fortawesome/free-solid-svg-icons'
import { ezwmDomains, rewardTypes } from './InstitutionEdit'
import { DataGrid } from '@mui/x-data-grid'

const fontStyle = {
    padding: '2%',
    color: '#d4731c',
    textTransform: 'normal',
    textAlign: 'center',
    fontSize: '1rem',
}

const menuElementContainer = {
    display: 'flex',
    width: '100%',
    minWidth: 250,
    minHeight: 100,
    maxHeight: 100,
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2em',
    textDecoration: 'none',
}

const departmentAccesses = { 21: 'opiekun', 25: 'admin DPS' }

export default (props) => {
    const { payerId } = useParams()
    const { isLoading, permissions } = usePermissions()
    const { data: userData, isLoading: userLoading } = useGetOne('pk_users', {
        id: `pk_users/me`,
    })
    const currentUrl = window.location.href

    return (
        <Card sx={{ display: 'flex', width: '100%', padding: 4 }}>
            <Box flex={1} marginRight={5}>
                <Typography variant="h5">Dane podstawowe</Typography>
                <Show resource="payers" id={payerId} actions={null} title={<CustomTitle resourceName="Instytucja" fields={['name']} />}>
                    <SimpleShowLayout>
                        <TextField source="name" label="Nazwa" />
                        <TextField source="nip" label="NIP" />
                        <TextField source="clientNumber" label="Numer klienta" />
                        <Divider sx={{ margin: '10px 0' }} />
                        <TextField source="street" label="Ulica" />
                        <TextField source="postCode" label="Kod pocztowy" />
                        <TextField source="city" label="Miejscowość" />
                        <TextField source="_voivodeship" label="Województwo" />
                        <Divider sx={{ margin: '10px 0' }} />
                        <TextField source="reward" label="Gromadzenie pkt." emptyText='Brak' />
                        <FunctionField
                            render={(record) => ezwmDomains.find((r) => r.id == record.ezwmDomain)?.name ?? 'Brak'}
                            label="Oddział"
                        />
                        <Divider sx={{ margin: '10px 0' }} />
                        <FunctionField
                            render={(record) => {
                                const rows = JSON.parse(record?.pricelist ?? '[]')
                                return rows?.length>0 ? (
                                    <DataGrid
                                        rows={rows}
                                        columns={[
                                            { field: 'sku' },
                                            { field: 'price', headerName: 'cena',  },
                                            { field: 'documentReference', headerName: 'nr umowy'},
                                            { field: 'createdAt', headerName: 'utworzono' },
                                            { field: 'editedAt', headerName: 'zaktulizowano' },
                                        ]}
                                    />
                                ): 'Brak'
                            }}
                            label="Cennik z umów"
                        />
                    </SimpleShowLayout>
                </Show>
            </Box>
            <Box flex={1}>
                <Box>
                    <Typography variant="h5">Moje dane</Typography>
                    {!userLoading && (
                        <Card
                            sx={{
                                marginTop: '1em',
                                padding: '8px 16px',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <TextFieldShowView label="Imię" text={userData?.firstName} />
                            <TextFieldShowView label="Nazwisko" text={userData?.lastName} />
                            <TextFieldShowView label="E-mail" text={userData?.email} />
                            <TextFieldShowView label="Telefon" text={userData?.phone} />
                            <Divider sx={{ margin: '10px 0' }} />
                            <TextFieldShowView label="Uprwanienia" text={departmentAccesses?.[userData?.departmentPermissions]} emptyText="Brak" />
                            <TextFieldShowView label="Odbiór nagród" text={userData?.rewardAccess} isBool={true} />
                        </Card>
                    )}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        marginTop: '10px',
                        justifyContent: 'space-between',
                    }}
                >
                    <Link to={`/pickup_points?payer=${payerId}`} style={menuElementContainer} className="dash-card" sx={{ marginRight: '10px' }}>
                        <FontAwesomeIcon icon={faStoreAlt} inverse mask="fa-light" />
                        <span style={fontStyle}>Punkty odbioru</span>
                    </Link>
                    <Link to={`/departments?payer=${payerId}`} style={menuElementContainer} className="dash-card">
                        <FontAwesomeIcon icon={faBuilding} inverse mask="fa-light" />
                        <span style={fontStyle}>Oddziały</span>
                    </Link>
                </Box>
                {PermissionHandler(permissions, 'Institution', 'Update') && (
                    <Button variant="outlined" color="primary" fullWidth sx={{ marginTop: '15px' }} href={`${currentUrl}/edit`}>
                        Edytuj
                    </Button>
                )}
            </Box>
        </Card>
    )
}
